import request from "@/utils/request";

/**
 * 分页查询我的浏览记录
 * @param pageNum
 * @param pageSize
 * @returns {AxiosPromise}
 */
// export function getMyBrowseList({ type, pageNum, pageSize = 20 } = {}) {
//   return request({
//     url: '/api/zeekr-article/bbs/browse/my-browse',
//     method: 'get',
//     params: { type, pageNum, pageSize }
//   })
// }
export function getMyBrowseList({ queryType, pageNum, pageSize = 20 } = {}) {
  return request({
    url: '/api/zeekr-article-new/browse/listMine',
    method: 'post',
    data: { queryType, pageNum, pageSize }
  })
}

/**
 * 删除浏览记录
 * @param ids
 * @returns {AxiosPromise}
 */
export function deleteBrowse(data) {
  return request({
    // url: '/api/zeekr-article/bbs/browse/delect',
    url: '/api/zeekr-article-new/browse/batchDeleteMine',
    method: 'post',
    data
  })
}