<template>
  <div class="browse">
    <pageHead :is-show-page-head="true" title="浏览记录">
      <div slot="right" class="edit" @click="handleEdit">{{ rightText }}</div>
    </pageHead>
    <van-tabs
      v-model="active"
      title-active-color="#FF4533"
      title-inactive-color="#999999"
      @click="changeType"
    >
      <van-tab v-for="(item, index) in tabOptions" :key="index" :title="item" />
    </van-tabs>
    <div
      infinite-scroll-throttle-delay="500"
      infinite-scroll-distance="50"
      v-infinite-scroll="loadMore"
      class="browse_content"
      ref="browseContent"
    >
      <van-checkbox-group v-model="checkResult" ref="checkboxGroup">
        <browse-topic
          v-for="(item, index) in topicData"
          :key="index"
          v-bind="item"
          class="topicItem"
          @click="handleTopicClick(item.entityDetail.articleId)"
          :showCheck="edit"
        />
      </van-checkbox-group>
      <no-more
        v-if="!listQueryParams.hasNextPage && topicData.length > 0"
        style="margin-top: 14px"
      />
      <van-empty v-if="noData" description="暂无数据" />
    </div>
    <div v-if="edit" class="flex-row-space-between-hCenter page-bottom">
      <div slot="left" class="page-bottom-left">
        <van-checkbox
          v-model="allChecked"
          checked-color="#FF4533"
          @click="handleCheckAll"
        />
        <span>全选</span>
      </div>
      <van-button type="danger" round slot="right" @click="handleDelete"
      >删除
      </van-button>
    </div>
  </div>
</template>

<script>
import {getMyBrowseList, deleteBrowse} from "@/api/browse";

import pageHead from "@/components/pageHead";
import NoMore from "@/components/uitls/NoMore";
import browseTopic from "./components/browseTopic";
import {getItem, setItem} from "@/utils/storage";

export default {
  components: {
    pageHead,
    NoMore,
    browseTopic,
  },

  computed: {
    rightText () {
      return this.edit ? "取消" : "编辑";
    },
  },
  watch: {
    checkResult (val) {
      if (this.allChecked) {
        if (val.length < this.topicData.length) {
          this.allChecked = false;
        }
      } else {
        if (val.length == this.topicData.length) {
          this.allChecked = true;
        }
      }
    },
  },
  beforeRouteEnter (to, from, next) {
    next((that) => {
      if (from.name === 'mineMain') {
        that.active = 0
      } else {
        const browseActive = getItem('browseActive')
        if (browseActive) {
          that.active = browseActive

        } else {
          that.active = 0
        }
      }
    })
  },
  data () {
    return {
      tabOptions: ["全部", "本周", "今天"],
      edit: false,
      pageNum: 1,
      topicData: [],
      listQueryParams: {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
      },
      checkResult: [],
      allChecked: false,
      active: 0,
      noData: false,
    };
  },
  methods: {
    // 点击编辑按钮
    handleEdit () {
      this.edit = !this.edit;
      if (this.edit === false) {
        this.checkResult = [];
      }
    },
    // 全选
    handleCheckAll () {
      if (this.checkResult.length == this.topicData.length) {
        this.$refs.checkboxGroup.toggleAll(false);
      } else {
        this.$refs.checkboxGroup.toggleAll(true);
      }
    },
    // 删除确认
    handleDelete () {
      this.$dialog.confirm({
        title: '提示',
        message: '删除后无法恢复，是否继续删除？',
      }).then(() => {
        this.deleteBrowse()
      })
    },
    /**
     * 删除接口
     */
    deleteBrowse () {
      deleteBrowse(this.checkResult).then(() => {
        this.topicData = [];
        this.listQueryParams.pageNum = 1;
        this.listQueryParams.hasNextPage = true;
        this.loadList();
      });
      this.edit = false;
      this.allChecked = false;
      this.$toast.success({
        message: "删除成功~",
        position: 'top'
      });
    },
    // 修改时间区间
    changeType (name) {
      setItem('browseActive', this.active)

      this.edit = false;
      this.topicData = [];
      this.listQueryParams = {
        pageNum: 1,
        pageSize: 10,
        hasNextPage: true,
        // type: name + 1,
        queryType: name,
      };
      if (this.listQueryParams.queryType === 0) {
        delete this.listQueryParams.queryType;
      }
      if (this.$refs.browseContent.scrollTop == 0) {
        this.loadList();
      }
    },
    loadMore () {
      this.listQueryParams.queryType = this.active
      if (this.listQueryParams.queryType === 0) {
        delete this.listQueryParams.queryType;
      }
      this.loadList();
    },
    loadList () {
      let listQueryParams = this.listQueryParams;
      if (listQueryParams.hasNextPage) {
        getMyBrowseList(this.listQueryParams).then((res) => {
          listQueryParams.hasNextPage = res.current < res.pages;
          listQueryParams.pageNum = listQueryParams.pageNum + 1;
          let resData = this.topicData;
          res.records.forEach((item) => {
            let count = resData.filter((innerItem) => {
              innerItem.browseId === item.browseId;
              // console.log(innerItem);
            });
            if (count.length === 0) {
              resData.push(item);
            }
          });
          this.topicData = resData;
          if (this.topicData.length === 0) {
            this.noData = true;
          }
          // console.log(this.topicData);
        });
      }
    },
    handleTopicClick (id) {
      this.$router.push({name: "topicDetail", query: {id}});
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .van-dialog__message {
    padding: 100px !important;
  }
}

.browse {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .van-tabs {
    background-color: white;

    ::v-deep .van-tabs__wrap {
      height: 35px;

      .van-tabs__nav {
        width: 180px;

        .van-tab {
          font-size: 14px;
        }
      }
    }
  }
}

.edit {
  font-size: 12px;
  color: $remarkColor;
}

.browse_content {
  position: relative;
  overflow-y: auto;
  flex: 1;
  padding-bottom: 48px;

  .topicItem {
    margin-top: 10px;
  }
}

.no-more {
  margin-top: 10px;
}

.page-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 12px;
  padding: 0 24px;
  color: $mainTextColor;
  height: 50px;
  box-shadow: 1px 0 1px $lightGray;
  background-color: #ffffff;

  &-left {
    display: flex;

    .van-checkbox {
      margin-right: 15px;
      margin-left: -4px;
    }
  }

  .van-button {
    height: 32px;
    width: 66px;
    font-size: 12px;
  }
}
</style>
