<template>
  <div class="browser-topic">
    <van-checkbox
      v-model="checked"
      v-if="showCheck"
      :name="browseId"
      checked-color="#FF4533"
    />
    <div @click="handleClick" class="browse-content">
      <userInfoView
        :userBasicVO="entityDetail.userBasicVO"
        :icon-url="isAnonymous ? '' : entityDetail.userBasicVO.avatar || ''"
        :admin="entityDetail.userBasicVO.superAdmin"
        :user-name="isAnonymous ? '匿名' : entityDetail.userBasicVO.username"
        :time="entityDetail.createTime"
      />
      <div class="contentView">
        <div
          class="title"
          v-html="entityDetail.title || entityDetail.content"
        />
        <div v-if="showImage" class="contentView_imgList">
          <img
            class="contentView_contentImg"
            :src="
              item ||
              entityDetail.videoUrl + '?x-oss-process=video/snapshot,t_50,f_jpg,ar_auto'
            "
            v-for="(item, index) in imgList"
            :key="index"
          />
        </div>
        <overview v-if="isForward" v-bind="originalDataFormat" />
      </div>
    </div>
  </div>
</template>

<script>
import userInfoView from "@/components/topicItem/components/userInfo";
import overview from "@/components/topicItem/components/overview";
import * as quoteType from "@/constant/quoteType";
import { ADMIN_NAME } from "@/config/config";

export default {
  components: {
    userInfoView,
    overview,
  },
  computed: {
    isAnonymous() {
      const { isAnonymous } = this.entityDetail;
      // return parseInt(anonymous) === 1;
      return isAnonymous;
    },
    imgList() {
      const { imageList } = this.entityDetail;
      // return (imageList || "").split(",").slice(0, 3);
      return imageList;
    },
    showImage() {
      return (
        this.entityDetail.imageList.length > 0 || this.entityDetail.videoUrl
      );
    },
    isForward() {
      return quoteType.FORWARD === this.entityDetail.userBasicVO.quoteType;
    },
    originalDataFormat() {
      let {
        quoteArticleVO: {
          content,
          title,
          id,
          imageList,
          username,
          anonymous,
          superAdmin,
          isVote,
          voteCount,
          voteFlag,
          voteStartTime,
          voteEndTime,
          voteList,
        } = {},
      } = this.entityDetail.userBasicVO;
      const [imgUrl] = (imageList || "").split(",");
      if (anonymous) {
        username = "匿名";
      } else if (superAdmin === 1 || superAdmin === 2) {
        username = ADMIN_NAME;
      }
      return {
        content,
        title,
        id,
        imgUrl,
        username,
        isVote,
        voteCount,
        voteFlag,
        voteStartTime,
        voteEndTime,
        voteList,
      };
    },
  },
  props: {
    browseId: [String, Number],
    entityDetail: Object,
    showCheck: Boolean,
  },
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    handleClick() {
      if (this.entityDetail.userBasicVO.status !== -1)
        this.$emit("click", this.entityDetail.userBasicVO);
    },
  },
};
</script>

<style lang="scss" scoped>
.browser-topic {
  padding: 20px;
  background-color: white;
  display: flex;
  align-items: center;

  .van-checkbox {
    padding-right: 20px;
  }

  .browse-content {
    width: 100%;

    .title {
      font-size: 14px;
      color: $mainTextColor;
      margin: 10px 0;
      @include ellipsis(1);
    }

    .contentView_imgList {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 10px;

      .contentView_contentImg {
        width: 100px;
        height: 100px;
        margin-right: 4px;
        margin-bottom: 4px;
        //border-radius: 6px;
        object-fit: cover;
      }
    }
  }
}
</style>
